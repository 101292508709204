<template>
  <div class="page" v-for="(chunk, chunkIndex) in chunkOfBody" :key="`chunk${chunkIndex}`">
    <div class="subpage" v-if="classroom">
      <div class="page-top-title">
        สรุปผลการประเมินพัฒนาการ เด็ก{{ getGradeLevelTitle(classroom.gradeLevel) }} ปีการศึกษา {{ schoolYear.year }}
      </div>
      <table width="100%">
        <thead :style="{ 'text-align': 'center' }">
          <tr v-for="(row, hIndex) in headers" :key="`h${hIndex}`">
            <td v-for="(col, cIndex) in row" :key="`c${cIndex}`" :colspan="col.colspan" :rowspan="col.rowspan">
              {{ col.title }}
            </td>
          </tr>
        </thead>
        <tbody :style="{ 'text-align': 'center' }">
          <tr v-for="(row, rIndex) in chunk" :key="`br${rIndex}`">
            <td v-for="(col, cIndex) in row" :key="`bc${cIndex}`">
              {{ col }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';

import { getGradeLevelTitle } from '@/components/utilities/utilities';
import { notification } from 'ant-design-vue';

const updateNotificationWarning = title => {
  notification.warning({
    message: title,
  });
};
const updateNotificationSuccess = title => {
  notification.success({
    message: title,
  });
};

const getHeaderRows = topicList => {
  const attributes = topicList.map(topic => topic.attributes).flat();
  const row1 = [
    {
      title: 'เลขที่',
      colspan: 1,
      rowspan: 4,
    },
    {
      title: 'มาตรฐานคุณลักษณะที่พึงประสงค์',
      colspan: attributes.length + 4,
      rowspan: 1,
    },
    {
      title: 'หมายเหตุ',
      colspan: 1,
      rowspan: 4,
    },
  ];
  const row2 = [
    {
      title: 'พัฒนาการ',
      rowspan: 1,
      colspan: attributes.length + 4,
    },
  ];
  const row3 = topicList.map(topic => {
    return {
      title: topic.title.replace('พัฒนาการ', ''),
      colspan: topic.attributes.length + 1,
      rowspan: 1,
    };
  });
  const row4 = topicList
    .map(topic => {
      const attributes = topic.attributes.map(attr => {
        return {
          title: attr.order,
          rowspan: 1,
          colspan: 1,
        };
      });
      const summary = { title: 'สรุป', rowspan: 1, colspan: 1 };
      return [...attributes, summary];
    })
    .flat();

  return [row1, row2, row3, row4];
};

const getBody = (students, topicList, reports, termId) => {
  console.log(reports);
  const templates = topicList
    .map(topic => {
      const attributes = topic.attributes.map(attr => {
        return {
          id: attr.id,
          type: 'attribute',
        };
      });
      const summary = { id: topic.id, type: 'topic' };
      return [...attributes, summary];
    })
    .flat();

  return students.map(student => {
    const topicReports = reports.topics.filter(item => item.studentId == student.id && item.termId == termId);
    const attributeReports = reports.attributes.filter(item => item.studentId == student.id && item.termId == termId);
    const scores = templates.map(template => {
      if (template.type == 'attribute') {
        const report = attributeReports.find(item => item.attributeId == template.id);
        if (!report) return 0;
        return report.averageScore;
      } else if (template.type == 'topic') {
        const report = topicReports.find(item => item.topicId == template.id);
        if (!report) return 0;
        return Math.round(report.averageScore);
      }
      return 0;
    });
    return [student.studentNo, ...scores, ''];
  });
};

export default {
  props: { classroomId: Number },
  setup(props) {
    const { state, dispatch } = useStore();

    const developmentReports = computed(() => state.print.developmentReport);
    const topicList = computed(() => state.evaluateForm.currentForm);
    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == props.classroomId);
      if (!found) return null;

      return found;
    });

    const schoolYear = computed(() => state.schoolYear.selected);
    const schoolInfos = computed(() => state.school.infos);
    const headers = ref([]);
    const bodies = ref([]);
    const chunkOfBody = ref([]);

    const chunkArray = (array, chunkSize) => {
      const chunkedArray = [];
      const arrayCopy = [...array];
      while (arrayCopy.length) {
        chunkedArray.push(arrayCopy.splice(0, chunkSize));
      }
      return chunkedArray;
    };

    const fetchHeaders = async () => {
      updateNotificationWarning('กำลังจัดเรียงตารางสรุปพัฒนาการ');
      await dispatch('getEvaluateForm', {
        standardYear: schoolYear.value.standardYear,
        gradeLevel: classroom.value.gradeLevel,
      });
      const termId = schoolYear.value.terms.find(term => term.termNo == 2).id;
      headers.value = getHeaderRows(topicList.value);
      bodies.value = getBody(classroom.value.studentInfos, topicList.value, developmentReports.value, termId);
      chunkOfBody.value = chunkArray(bodies.value, 15);
      updateNotificationSuccess('จัดเรียงตารางสรุปพัฒนาการเรียบร้อยแล้ว');
    };

    watch(
      () => classroom.value,
      async () => {
        if (!topicList.value) {
          fetchHeaders();
        }
      },
    );

    onMounted(async () => {
      if (classroom.value) {
        fetchHeaders();
      }
    });

    return { classroom, schoolYear, schoolInfos, topicList, headers, chunkOfBody, getGradeLevelTitle };
  },
};
</script>

<style scoped>
thead tr {
  height: 35px;
}
</style>
