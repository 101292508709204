<template>
  <div class="page">
    <div class="subpage" v-if="classroom">
      <div>
        <img v-if="schoolInfos.logoUrl" :src="schoolInfos.logoUrl" class="logo-img" />
        <img
          v-else
          src="https://firebasestorage.googleapis.com/v0/b/rathakul-backoffice.appspot.com/o/school_logos%2Fdla_logo.jpg?alt=media&token=83f179e6-fbd6-458b-bbd4-2a43c0b18e2e"
          class="logo-img"
        />
      </div>
      <div class="cover-title" :style="{ 'margin-top': '10mm' }">
        บัญชีเรียกชื่อ และบันทึกผลการประเมินพัฒนาการเด็กปฐมวัย
      </div>
      <div class="cover-content">
        <div>ชั้น {{ getGradeLevelTitle(classroom.gradeLevel) }} ห้อง {{ classroom.title }}</div>
        <div :style="{ 'margin-top': '5mm' }">ปีการศึกษา {{ schoolYear.year }}</div>
        <div :style="{ 'margin-top': '10mm' }">สถานศึกษา</div>
        <div :style="{ 'margin-top': '5mm' }">{{ schoolInfos.title }}</div>
        <div :style="{ 'margin-top': '10mm' }">สังกัด {{ nullSafer(schoolInfos.educationalServiceArea) }}</div>
        <div :style="{ 'margin-top': '5mm' }">{{ getAddressLine(schoolInfos.address) }}</div>
      </div>
      <div class="sign">
        <div>(ลงชื่อ) ..............................................</div>
        <div>( {{ userInfos.firstname }} {{ userInfos.lastname }} )</div>
        <div>ครูประจำชั้น</div>
      </div>
      <div class="sign">
        <div>(ลงชื่อ) ..............................................</div>
        <div>( ............................... )</div>
        <div>ผู้บริหารสถานศึกษา</div>
      </div>
    </div>
    <a-spin :spinning="true" v-else>
      <div class="subpage"></div>
    </a-spin>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import { getGradeLevelTitle, nullSafer, getAddressLine } from '@/components/utilities/utilities';

export default {
  props: { classroomId: Number },
  setup(props) {
    const { state, getters } = useStore();

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == props.classroomId);
      if (!found) return null;
      return found;
    });

    const schoolYear = computed(() => state.schoolYear.selected);
    const schoolInfos = computed(() => state.school.infos);
    const userInfos = computed(() => getters.userProfile());

    return {
      schoolYear,
      schoolInfos,
      classroom,
      getGradeLevelTitle,
      nullSafer,
      userInfos,
      getAddressLine,
    };
  },
};
</script>
>
