<template>
  <div v-if="classroom">
    <div class="page" v-for="(chunk, chunkIndex) in chunkOfStudents" :key="`chunk${chunkIndex}`">
      <div class="subpage">
        <div class="page-top-title">ข้อมูลเกี่ยวกับเด็ก</div>
        <table width="100%">
          <thead class="center">
            <tr>
              <td>เลขที่</td>
              <td>ชื่อ - สกุล</td>
              <td>เลขประจำตัว</td>
              <td>วันเดือนปีเกิด</td>
              <td>อายุ</td>
              <td>ชื่อเล่น</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="student in chunk" :key="student.id">
              <td class="center">{{ student.studentNo }}</td>
              <td>{{ student.firstname }} {{ student.lastname }}</td>
              <td>{{ student.studentCode }}</td>
              <td class="center">{{ fulldateString(student.dateOfBirth) }}</td>
              <td class="center">{{ getStudentAge(student) }}</td>
              <td class="center">{{ student.nickname ? student.nickname : '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { fulldateString, getStudentAge } from '@/components/utilities/utilities';

export default {
  props: { classroomId: Number },
  setup(props) {
    const { state } = useStore();

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == props.classroomId);
      if (!found) return null;

      return found;
    });

    const students = computed(() => {
      const list = classroom.value.studentInfos;
      return list.sort((a, b) => {
        return a.studentNo - b.studentNo;
      });
    });

    const chunkArray = (array, chunkSize) => {
      console.log({ array });
      const chunkedArray = [];
      const arrayCopy = [...array];
      while (arrayCopy.length) {
        chunkedArray.push(arrayCopy.splice(0, chunkSize));
      }
      return chunkedArray;
    };

    const chunkOfStudents = ref([]);

    const chunkSize = 15;
    chunkOfStudents.value = chunkArray(students.value, chunkSize);

    return { classroom, students, fulldateString, getStudentAge, chunkOfStudents };
  },
};
</script>

<style scoped>
thead tr {
  height: 70px;
}

tbody tr {
  height: 20px;
}
</style>
