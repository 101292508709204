<template>
  <div class="page">
    <div class="subpage" v-if="classroom">
      <div class="cover-title" :style="{ 'margin-top': '30mm' }">แบบบันทึกผลการประเมินพัฒนาการ</div>
      <div class="cover-content">
        <div :style="{ 'margin-top': '5mm' }">
          เด็ก{{ getGradeLevelTitle(classroom.gradeLevel) }} ห้อง {{ classroom.title }}
        </div>
        <div :style="{ 'margin-top': '5mm' }">ปีการศึกษา {{ schoolYear.year }}</div>
        <div :style="{ 'margin-top': '10mm' }">สถานศึกษา</div>
        <div :style="{ 'margin-top': '5mm' }">{{ schoolInfos.title }}</div>
        <div :style="{ 'margin-top': '10mm' }">สังกัด {{ nullSafer(schoolInfos.educationalServiceArea) }}</div>
        <div :style="{ 'margin-top': '5mm' }">{{ getAddressLine(schoolInfos.address) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { getGradeLevelTitle, nullSafer, getAddressLine } from '@/components/utilities/utilities';

export default {
  props: { classroomId: Number },
  setup(props) {
    const { state } = useStore();

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == props.classroomId);
      if (!found) return null;

      return found;
    });

    const schoolYear = computed(() => state.schoolYear.selected);
    const schoolInfos = computed(() => state.school.infos);

    return { classroom, schoolYear, schoolInfos, getGradeLevelTitle, getAddressLine, nullSafer };
  },
};
</script>
