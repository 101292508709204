<template>
  <div v-if="!classroom"></div>
  <div v-else>
    <div class="page" v-for="(page, pageIndex) in pages" :key="`devTable-${pageIndex}`">
      <div class="subpage">
        <header>
          <div class="table-title">{{ page.title }}</div>
          <div class="table-subtitle">{{ page.subtitle }}</div>
        </header>
        <table width="100%" v-if="page.rows">
          <thead>
            <tr v-for="(row, rowIndex) in page.rows" :key="`${pageIndex}${rowIndex}`">
              <td v-if="rowIndex == 0" rowspan="4" class="vertical">
                <div class="vertical">เลขที่</div>
              </td>
              <td
                v-for="(col, colIndex) in row"
                :key="`${pageIndex}${rowIndex}${colIndex}`"
                :rowspan="col.rowspan"
                :colspan="col.colspan"
                :class="`${col.class}`"
                :valign="col.align"
                :width="col.width ? col.width : 'auto'"
                style="padding: 4px 4px 4px 4px;"
              >
                {{ col.title }}
              </td>
            </tr>
          </thead>
          <tbody :style="{ 'text-align': 'center' }">
            <tr v-for="body in page.body" :key="body.studentId" :style="{ height: '35px' }">
              <td colspan="1" class="text-center">
                {{ body.studentNo }}
              </td>
              <td v-for="(score, scoreIndex) in body.value" :key="scoreIndex">
                {{ score }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';

const updateNotificationWarning = title => {
  notification.warning({
    message: title,
  });
};
const updateNotificationSuccess = title => {
  notification.success({
    message: title,
  });
};

const columnReducer = (accumulator, topic) => {
  topic.attributes.forEach(attr => {
    attr.indicators.forEach(indicator => {
      const conditions = indicator.conditions.map(condition => {
        return {
          id: condition.id,
          order: condition.order,
          title: `${condition.order}.${condition.title}`,
          indicator: {
            id: indicator.id,
            order: indicator.order,
            title: `ตัวบ่งชี้ที่ ${indicator.order} ${indicator.title}`,
          },
          attribute: {
            id: attr.id,
            order: attr.order,
            title: attr.title,
          },
          topic: {
            id: topic.id,
            order: topic.order,
            title: topic.title,
          },
          type: 'condition',
        };
      });
      accumulator = accumulator.concat(conditions).concat({
        indicator: {
          id: indicator.id,
          order: indicator.order,
          title: indicator.title,
        },
        attribute: {
          id: attr.id,
          order: attr.order,
          title: attr.title,
        },
        topic: {
          id: topic.id,
          order: topic.order,
          title: topic.title,
        },
        id: indicator.id,
        title: `สรุปตัวบ่งชี้ที่ ${indicator.order}`,
        type: 'summary-indicator',
      });
    });
    accumulator = accumulator.concat({
      attribute: {
        id: attr.id,
        order: attr.order,
        title: attr.title,
      },
      topic: {
        id: topic.id,
        order: topic.order,
        title: topic.title,
      },
      id: attr.id,
      title: `สรุปผลการประเมินพัฒนาการมาตรฐานที่ ${attr.order}`,
      type: 'summary-attribute',
    });
  });

  return accumulator;
};

const getPageTopic = columns => {
  const length = columns.length;
  const lastCol = columns[length - 1];
  return lastCol.topic.title;
};

const getPageSubtitle = columns => {
  const length = columns.length;
  const lastCol = columns[length - 1];
  return `มาตรฐานที่ ${lastCol.attribute.order} ${lastCol.attribute.title}`;
};

const getFirstRow = chunk => {
  let rowObject = {};
  chunk.forEach((item, chunkIndex) => {
    if (item.indicator) {
      if (!rowObject[item.indicator.id]) {
        rowObject[item.indicator.id] = {
          chunkIndex,
          id: item.indicator.id,
          title: item.indicator.title,
          type: 'indicator-title',
          colspan: item.type.includes('summary-') ? 2 : 6,
          rowspan: item.type === 'summary-attribute' ? 2 : 1,
          class: 'text-left font-weight-bold',
          align: 'middle',
        };
      } else {
        rowObject[item.indicator.id].colspan += item.type.includes('summary-') ? 2 : 6;
      }
    } else {
      if (item.type == 'summary-attribute') {
        rowObject[item.attribute.id] = {
          chunkIndex,
          id: item.attribute.id,
          title: item.title,
          type: 'summary-attrubute',
          colspan: 2,
          rowspan: 2,
          class: 'text-center font-weight-bold',
          align: 'middle',
        };
      }
    }
  });

  let rows = Object.values(rowObject).sort((itemA, itemB) => {
    return itemA.chunkIndex > itemB.chunkIndex;
  });
  return rows;
};

const getSecondRow = chunk => {
  let row2 = [];
  chunk.forEach(item => {
    if (['condition', 'summary-indicator'].includes(item.type)) {
      row2.push({
        title: item.title,
        colspan: item.type === 'condition' ? 6 : 2,
        class: item.type === 'condition' ? 'text-left' : 'text-center font-weight-bold',
        align: item.type === 'condition' ? 'top' : 'middle',
      });
    }
  });
  return row2;
};

const getThirdRow = chunk => {
  let row3 = [];
  let rowClass = 'text-center';
  chunk.forEach(item => {
    if (item.type.includes('summary-')) {
      row3.push({
        type: item.type,
        title: 'ภาคเรียนที่',
        colspan: 2,
        rowspan: 1,
        class: rowClass,
        align: 'middle',
      });
    } else {
      row3.push({
        type: item.type,
        title: 'ภาคเรียนที่ 1',
        colspan: 3,
        rowspan: 1,
        class: rowClass,
        align: 'middle',
      });
      row3.push({
        type: item.type,
        title: 'ภาคเรียนที่ 2',
        colspan: 3,
        rowspan: 1,
        class: rowClass,
        align: 'middle',
      });
    }
  });
  return row3;
};

const getFourthRow = (chunk, schoolYear) => {
  let row4 = [];
  let rowClass = 'text-center';
  const term1 = schoolYear.terms.find(term => term.termNo == 1);
  const term2 = schoolYear.terms.find(term => term.termNo == 2);
  chunk.forEach(item => {
    const topicId = item.topic.id;
    const attributeId = item.attribute.id;
    if (item.type.includes('summary-')) {
      row4.push({
        title: '1',
        class: rowClass,
        type: item.type,
        itemId: item.id,
        topicId,
        term: 1,
        termId: term1.id,
        attributeId,
      });
      row4.push({
        title: '2',
        class: rowClass,
        type: item.type,
        itemId: item.id,
        topicId,
        term: 2,
        termId: term2.id,
        attributeId,
      });
    } else {
      const indicatorId = item.indicator.id;
      row4.push({
        title: 'ครั้งที่ 1',
        class: rowClass,
        type: item.type,
        term: 1,
        time: 1,
        itemId: item.id,
        termId: term1.id,
        topicId,
        attributeId,
        indicatorId,
      });
      row4.push({
        title: 'ครั้งที่ 2',
        class: rowClass,
        type: item.type,
        term: 1,
        termId: term1.id,
        time: 2,
        itemId: item.id,
        topicId,
        attributeId,
        indicatorId,
      });
      row4.push({
        title: 'สรุป',
        class: 'text-center font-weight-bold',
        type: `summary-${item.type}`,
        itemId: item.id,
        term: 1,
        termId: term1.id,
        topicId,
        attributeId,
        indicatorId,
      });
      row4.push({
        title: 'ครั้งที่ 1',
        class: rowClass,
        type: item.type,
        term: 2,
        termId: term2.id,
        time: 1,
        itemId: item.id,
        topicId,
        attributeId,
        indicatorId,
      });
      row4.push({
        title: 'ครั้งที่ 2',
        class: rowClass,
        type: item.type,
        term: 2,
        termId: term2.id,
        time: 2,
        itemId: item.id,
        topicId,
        attributeId,
        indicatorId,
      });
      row4.push({
        title: 'สรุป',
        class: 'text-center font-weight-bold',
        type: `summary-${item.type}`,
        term: 2,
        termId: term2.id,
        itemId: item.id,
        topicId,
        attributeId,
        indicatorId,
      });
    }
  });
  return row4;
};

const getPageBody = (students, row4, reports) => {
  const bodies = students.map(student => {
    const foundedAttribute = reports.attributes.filter(item => {
      return item.studentId == student.id;
    });
    const foundedIndicator = reports.indicators.filter(item => {
      return item.studentId == student.id;
    });
    const foundedCondition = reports.conditions.filter(item => {
      return item.studentId == student.id;
    });

    if (!foundedCondition || !foundedIndicator || !foundedAttribute) {
      return {
        studentId: student.id,
        studentNo: student.studentNo,
        value: [],
      };
    }

    return {
      studentId: student.id,
      studentNo: student.studentNo,
      value: row4.map(header => {
        if (header.type == 'condition') {
          const found = foundedCondition.find(
            cond => cond.termId == header.termId && cond.conditionId == header.itemId,
          );
          if (!found) {
            return 0;
          }
          return found[`score${header.time}`];
        } else if (header.type == 'summary-condition') {
          const found = foundedCondition.find(
            cond => cond.termId == header.termId && cond.conditionId == header.itemId,
          );
          if (!found) {
            return 0;
          }
          return found.averageScore;
        } else if (header.type == 'summary-indicator') {
          const found = foundedIndicator.find(
            item => item.termId == header.termId && item.indicatorId == header.itemId,
          );
          if (!found) {
            return 0;
          }
          return found.averageScore;
        } else if (header.type == 'summary-attribute') {
          const found = foundedAttribute.find(
            item => item.termId == header.termId && item.attributeId == header.itemId,
          );
          if (!found) {
            return 0;
          }
          return found.averageScore;
        }
      }),
    };
  });

  return bodies;
};

const headerMapper = (columns, index, all, schoolYear) => {
  const title = getPageTopic(columns);
  const subtitle = getPageSubtitle(columns);
  const row1 = getFirstRow(columns);
  const row2 = getSecondRow(columns);
  const row3 = getThirdRow(columns);
  const row4 = getFourthRow(columns, schoolYear);

  if (index == all.length - 1) {
    const remarkWidth = (5 - row1.length) * 15;
    row1.push({
      id: null,
      title: 'หมายเหตุ',
      type: 'remark',
      colspan: 1,
      rowspan: 4,
      class: 'text-center font-weight-bold',
      align: 'middle',
      width: `${remarkWidth}%`,
    });
  }

  return {
    title,
    subtitle,
    rows: [row1, row2, row3, row4],
  };
};

const createPages = (topics, students, reports, schoolYear) => {
  const allColumns = topics.reduce(columnReducer, []);
  const columnChunks = allColumns.toChunk(5);

  const splitArrayIntoChunks = (arr, chunkSize) => {
    const results = [];
    while (arr.length) {
      results.push(arr.splice(0, chunkSize));
    }
    return results;
  };

  const studentChunks = splitArrayIntoChunks([...students], 15);

  const pages = columnChunks.flatMap((columns, index, all) => {
    const headers = headerMapper(columns, index, all, schoolYear);

    return studentChunks.map(studentChunk => {
      const body = getPageBody(studentChunk, headers.rows[3], reports);
      return {
        ...headers,
        body,
      };
    });
  });

  return pages;
};

export default {
  props: { classroomId: Number },
  setup(props) {
    const { state, dispatch } = useStore();

    const topicList = computed(() => state.evaluateForm.currentForm);
    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == props.classroomId);
      if (!found) return null;

      return found;
    });

    const developmentReport = computed(() => state.print.developmentReport);
    const schoolYear = computed(() => state.schoolYear.selected);
    const schoolInfos = computed(() => state.school.infos);
    const pages = ref([]);

    const fetchHeaders = async () => {
      updateNotificationWarning('กำลังจัดเรียงตารางบันทึกพัฒนา');
      await dispatch('getEvaluateForm', {
        standardYear: schoolYear.value.standardYear,
        gradeLevel: classroom.value.gradeLevel,
      });
      pages.value = createPages(
        topicList.value,
        classroom.value.studentInfos,
        developmentReport.value,
        schoolYear.value,
      );
      updateNotificationSuccess('จัดเรียงตารางบันทึกพัฒนาการเรียบร้อยแล้ว');
    };

    watch(
      () => classroom.value,
      async () => {
        if (!topicList.value) {
          fetchHeaders();
        }
      },
    );

    onMounted(async () => {
      if (classroom.value) {
        fetchHeaders();
      }
    });

    return { classroom, schoolYear, schoolInfos, topicList, pages };
  },
};

Array.prototype.toChunk = function(size) {
  const chunked_arr = [];
  let index = 0;
  while (index < this.length) {
    chunked_arr.push(this.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};
</script>
