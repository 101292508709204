<template>
  <div class="page">
    <div class="subpage" v-if="classroom">
      <div class="cover-title" :style="{ 'margin-top': '30mm' }">
        <div>บัญชีเรียกชื่อ</div>
        <div>(แบบบันทึกเวลามาเรียน)</div>
      </div>
      <div class="cover-content">
        <div>ชั้น {{ getGradeLevelTitle(classroom.gradeLevel) }}</div>
        <div :style="{ 'margin-top': '5mm' }">ปีการศึกษา {{ schoolYear.year }}</div>
        <div :style="{ 'margin-top': '10mm' }">สถานศึกษา {{ schoolInfos.title }}</div>
        <div :style="{ 'margin-top': '5mm' }">สังกัด {{ nullSafer(schoolInfos.educationalServiceArea) }}</div>
        <div :style="{ 'margin-top': '5mm' }">{{ getAddressLine(schoolInfos.address) }}</div>

        <table
          width="80%"
          :style="{ 'text-align': 'center', 'margin-top': '10mm', 'margin-left': 'auto', 'margin-right': 'auto' }"
        >
          <thead>
            <tr>
              <td>รายการ</td>
              <td>ชาย</td>
              <td>หญิง</td>
              <td>รวม</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rIndex) in bodies" :key="`r${rIndex}`">
              <td v-for="(col, cIndex) in row" :key="`c${cIndex}`">
                {{ col }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="cover-content" :style="{ 'margin-top': '10mm' }">
        เริ่มใช้เมื่อวันที่ {{ fulldateString(term1.startDate) }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { fulldateString, getGradeLevelTitle, nullSafer, getAddressLine } from '@/components/utilities/utilities';

const getBody = (students, terms) => {
  const term1 = terms.find(term => term.termNo == 1);

  const joinedBeforeTerm2 = students.filter(student => {
    const joinedAt = moment(student.joinedAt);
    const term1End = moment(term1.endDate);
    return joinedAt.isBefore(term1End);
  });

  const mJoinedBeforeTerm2 = joinedBeforeTerm2.filter(student => student.gender == 'MALE').length;
  const fJoinedBeforeTerm2 = joinedBeforeTerm2.filter(student => student.gender == 'FEMALE').length;

  const joinedInTerm2 = students.filter(student => {
    const joinedAt = moment(student.joinedAt);
    const term1End = moment(term1.endDate);
    return joinedAt.isAfter(term1End);
  });

  const mJoinedInTerm2 = joinedInTerm2.filter(student => student.gender == 'MALE').length;
  const fJoinedInTerm2 = joinedInTerm2.filter(student => student.gender == 'FEMALE').length;

  const leave = students.filter(student => student.status != 'ACTIVE');
  const mLeave = leave.filter(student => student.gender == 'MALE').length;
  const fLeave = leave.filter(student => student.gender == 'FEMALE').length;

  const conclutions = students.filter(student => student.status == 'ACTIVE');
  const mConclutions = conclutions.filter(student => student.gender == 'MALE').length;
  const fConclutions = conclutions.filter(student => student.gender == 'FEMALE').length;

  return [
    ['จำนวนนักเรียนในชั้นต้นปี', mJoinedBeforeTerm2, fJoinedBeforeTerm2, joinedBeforeTerm2.length],
    ['จำนวนนักเรียนที่เข้าเรียนระหว่างปี', mJoinedInTerm2, fJoinedInTerm2, joinedInTerm2.length],
    ['จำนวนเด็กที่ย้ายออกระหว่างปี', mLeave, fLeave, leave.length],
    ['จำนวนเด็กเมื่อวันสิ้นปี', mConclutions, fConclutions, conclutions.length],
  ];
};

export default {
  props: { classroomId: Number },
  setup(props) {
    const { state } = useStore();

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == props.classroomId);
      if (!found) return null;

      return found;
    });

    const schoolYear = computed(() => state.schoolYear.selected);
    const schoolInfos = computed(() => state.school.infos);
    const term1 = computed(() => schoolYear.value.terms.find(term => term.termNo == 1));

    const studentInfos = classroom.value ? classroom.value.studentInfos : [];
    const bodies = ref(getBody(studentInfos ?? [], schoolYear.value.terms));

    return {
      classroom,
      schoolYear,
      schoolInfos,
      bodies,
      term1,
      getGradeLevelTitle,
      fulldateString,
      nullSafer,
      getAddressLine,
    };
  },
};
</script>

<style scoped>
thead tr {
  height: 100px;
}
</style>
