<template>
  <PrintWrapper>
    <CoverPage :classroomId="classroomId" />
    <StudentInfosPage :classroomId="classroomId" />
    <AttendanceCoverPage :classroomId="classroomId" v-if="attendanceReport" />
    <AttendanceTablesPage :classroomId="classroomId" v-if="attendanceReport" />
    <DevelopmentCoverPage :classroomId="classroomId" v-if="developmentReport" />
    <DevelopmentTablesPage :classroomId="classroomId" v-if="developmentReport" />
    <DevelopmentSummaryPage :classroomId="classroomId" v-if="developmentReport" />
    <button class="printButton" @click="print">พิมพ์เอกสาร</button>
  </PrintWrapper>
</template>

<style scoped>
.printButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3f51b5;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media print {
  .printButton {
    display: none;
  }
}
</style>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { PrintWrapper } from './style';

import CoverPage from './overview/Classroom/CoverPaper.vue';
import StudentInfosPage from './overview/Classroom/StudentInfosPage.vue';
// import StudentParentPage from './overview/Classroom/StudentParentPage.vue';
// import StudentAddressPage from './overview/Classroom/StudentAddressPage.vue';
import AttendanceCoverPage from './overview/Classroom/AttendanceCoverPage.vue';
import AttendanceTablesPage from './overview/Classroom/AttendanceTablesPage.vue';
import DevelopmentCoverPage from './overview/Classroom/DevelopmentCoverPage.vue';
import DevelopmentTablesPage from './overview/Classroom/DevelopmentTablesPage.vue';
import DevelopmentSummaryPage from './overview/Classroom/DevelopmentSummaryPage.vue';

export default {
  components: {
    PrintWrapper,
    CoverPage,
    StudentInfosPage,
    AttendanceCoverPage,
    AttendanceTablesPage,
    DevelopmentCoverPage,
    DevelopmentTablesPage,
    DevelopmentSummaryPage,
  },
  props: {
    classroomId: Number,
  },
  setup(props) {
    const { dispatch, state } = useStore();

    const developmentReport = computed(() => state.print.developmentReport);
    const attendanceReport = computed(() => state.print.attendanceReport);
    const schoolYear = computed(() => state.schoolYear.selected);
    const classrooms = computed(() => state.classroom.classrooms);

    const print = () => {
      window.print();
    };

    onMounted(async () => {
      if (!classrooms.value || classrooms.value.length === 0) {
        await dispatch('getAllClassrooms', { schoolYearId: schoolYear.value.id });
        dispatch('updateStudentTable', classrooms.value);
      }

      dispatch('getDevelopmentReport', { classroomId: props.classroomId });
      dispatch('getAttendanceReport', { classroomId: props.classroomId });
    });

    return { developmentReport, attendanceReport, print };
  },
};
</script>
