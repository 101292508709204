<template>
  <div class="page" v-for="(chunk, chunkIndex) in chunkOfAttendances" :key="`chunk${chunkIndex}`">
    <div class="subpage">
      <div class="page-top-title">สรุปยอดประจำปีการศึกษา</div>
      <table width="100%">
        <thead class="center">
          <tr>
            <td rowspan="2" width="5%">เลขที่</td>
            <td rowspan="2" width="40%">ชื่อ - สกุล</td>
            <td colspan="4">ภาคเรียนที่ 1</td>
            <td colspan="4">ภาคเรียนที่ 2</td>
          </tr>
          <tr>
            <td>มา</td>
            <td>ป่วย</td>
            <td>ลา</td>
            <td>ขาด</td>
            <td>มา</td>
            <td>ป่วย</td>
            <td>ลา</td>
            <td>ขาด</td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, rowIndex) in chunk" :key="`row${rowIndex}`">
            <td v-for="(col, colIndex) in row" :key="`col${colIndex}`" :class="colIndex == 1 ? 'name' : ''">
              {{ col }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  props: { classroomId: Number },
  setup(props) {
    const { state } = useStore();

    const classroom = computed(() => {
      if (!state.classroom.classrooms) return null;
      const found = state.classroom.classrooms.find(item => item.id == props.classroomId);
      if (!found) return null;

      return found;
    });

    const attendanceReport = computed(() => state.print.attendanceReport);
    const schoolYear = computed(() => state.schoolYear.selected);
    const schoolInfos = computed(() => state.school.infos);
    const term1 = computed(() => schoolYear.value.terms.find(term => term.termNo == 1));
    const term2 = computed(() => schoolYear.value.terms.find(term => term.termNo == 2));
    const attendances = ref([]);
    const chunkOfAttendances = ref([]);

    const chunkArray = (array, chunkSize) => {
      console.log({ array });
      const chunkedArray = [];
      const arrayCopy = [...array];
      while (arrayCopy.length) {
        chunkedArray.push(arrayCopy.splice(0, chunkSize));
      }
      return chunkedArray;
    };

    onMounted(() => {
      if (!classroom.value) return;

      const { studentInfos: students } = classroom.value;
      attendances.value = students.map(student => {
        const studentAttendance = attendanceReport.value.find(item => item.studentId == student.id);
        let term1Data = studentAttendance.termly.find(item => item.termId == term1.value.id);
        let term2Data = studentAttendance.termly.find(item => item.termId == term2.value.id);

        if (!term1Data) {
          term1Data = { present: 0, sick: 0, leave: 0, absent: 0 };
        }

        if (!term2Data) {
          term2Data = { present: 0, sick: 0, leave: 0, absent: 0 };
        }

        return [
          student.studentNo,
          `${student.firstname} ${student.lastname}`,
          term1Data.present,
          term1Data.sick,
          term1Data.leave,
          term1Data.absent,
          term2Data.present,
          term2Data.sick,
          term2Data.leave,
          term2Data.absent,
        ];
      });

      const chunkSize = 15;
      chunkOfAttendances.value = chunkArray(attendances.value, chunkSize);
    });

    return { classroom, schoolYear, schoolInfos, attendances, chunkOfAttendances };
  },
};
</script>

<style scoped>
thead tr {
  height: 45px;
}

td.name {
  text-align: left;
  padding-left: 10px;
}
</style>
