import Styled from 'vue3-styled-components';

const PrintWrapper = Styled.div`
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    table,th,td {
        border: 1px solid black;
        border-collapse: collapse;
        font-size: 8pt;
        padding-left: 4px;
        padding-right: 4px;
    }
    td.vertical {
        width: 3%;
        text-align: inherit;
    }
    td.center {
        text-align: center;
    },
    thead.center {
        text-align: center; 
    }
    th.center-header {
        text-align: center;
    }
    
    div.cover-infos {
        margin-top: 10px;
    }
    
    div.vertical {
        margin-left: -5px;
        position: absolute;
        transform: rotate(-90deg);
        text-align: center;
        -webkit-transform: rotate(-90deg); /* Safari/Chrome */
        -moz-transform: rotate(-90deg); /* Firefox */
        -o-transform: rotate(-90deg); /* Opera */
        -ms-transform: rotate(-90deg); /* IE 9 */
    }
    .page {
        font-family: "Sarabun", san-serif;
        width: 210mm;
        min-height: 297mm;
        padding: 15mm 6mm 4mm 6mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .subpage {
        min-height: 278mm;
    }
    .cover-title {
        font-size: 28px;
        text-align: center;
    }
    .cover-content {
        font-size: 24px;
        text-align: center;
    }
    .page-top-title {
        text-align: center;
        margin-bottom: 5mm;
    }
    .table-title {
        text-align: center;
    }
    .table-subtitle {
        text-align: center;
        margin-bottom: 5mm;
    }

    .sign {
        font-size: 20px;
        text-align: center;
        margin-top: 10mm;
    }

    .logo-img {
        height: 35mm;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    @page {
        size: A4;
        margin: 0;
    }
    @media print {
        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
        .subpage {
            min-height: initial;
        }
        div.vertical {
            position: relative;
            margin-left: 0;
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            width: 5mm; /* Modify this value to fit the text on a single line */
            white-space: nowrap; /* Prevent line breaks */
        }
        
        td.vertical {
            page-break-inside: avoid;
        }
    }
`;
export { PrintWrapper };
